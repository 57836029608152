import React from "react";
import "../Styles/Footer.css";

const Footer = () => {
    return (
        <footer className="footer-container">
            <div className="footer-content">
                <div className="left">
                    <h1>Get in Touch</h1>
                    <p className="copyright">©2024 Mathias Foght Portfolio</p>
                </div>
                <div className="center">
                    <div className="center-content">
                        <h2>Code Community</h2>
                        <a href="https://github.com/dashboard" target="_blank">GitHub</a>
                        <a href="https://gitlab.au.dk/personlig" target="_blank">GitLab</a>
                    </div>
                </div>
                <div className="right">
                    <div className="right-content">
                        <h2>Connect</h2>
                        <a href="https://www.linkedin.com/in/mathias-foght-549197252/" target="_blank">LinkedIn</a>
                        <a href="https://www.instagram.com/mathias_foght/" target="_blank">Instagram</a>
                        <a href="https://www.facebook.com/mathias.foght?locale=da_DK" target="_blank">Facebook</a>
                        <a href="mailto:mathiasfoght98@gmail.com" target="_blank">Email</a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
