import React, { useState, useEffect } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import "../Styles/Contact.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import ScrollAnimation from '../Services/ScrollAnimation';

function Contact() {
  const [state, handleSubmit] = useForm("meojjygk");    // Formspree form ID
  const [buttonText, setButtonText] = useState('Send');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  // Change button to success message when form is submitted
  useEffect(() => {
    if (state.succeeded) {
      document.getElementById("name").value = "";
      document.getElementById("phone").value = "";
      document.getElementById("company").value = "";
      document.getElementById("email").value = "";
      document.getElementById("message").value = "";

      // Change button text to "Success message"
      setButtonText('Thanks for your inquiry!');
      setIsSuccessMessage(true);
      const timer = setTimeout(() => {
        setButtonText('Send');
        setIsSuccessMessage(false);
      }, 5000);

      return () => clearTimeout(timer); 
    }
  }, [state.succeeded]);

  // Validate form
  const validateForm = (event) => {
    const form = event.target;
    const errors = {};

    if (!form.name.value.trim()) {
      errors.name = true;
    }
    if (!form.email.value.trim()) {
      errors.email = true;
    }
    if (!form.message.value.trim()) {
      errors.message = true;
    }

    setValidationErrors(errors);

    return Object.keys(errors).length === 0;
  };

  // Handle submit with validation
  const handleSubmitWithValidation = (event) => {
    event.preventDefault();
    if (validateForm(event)) {
      handleSubmit(event);
    }
  };

  return (
    <div className="wrapper-contact" id="contactPage">
        <div className='contact-content'>
            <ScrollAnimation>
            <h1 className='contact-header'>Contact</h1>
            <h2 className='contact-subheader'>Let's work together</h2>
            <p className='contact-text'>
                Feel free to contact me with any
                <span className='mobile-break'> <br></br>inquiries or questions!</span>
                <span className='desktop-break'> inquiries or questions!</span>
            </p>

            <a href="https://www.linkedin.com/in/mathias-foght-549197252/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedin} size="2x" className="linkedin-icon" />
            </a>
            </ScrollAnimation>
        </div>
        <ScrollAnimation>
        <form className="login-form" onSubmit={handleSubmitWithValidation}>
            <div className="form-input-material">
              <input
                  type="text"
                  name="Customer Name"
                  id="name"
                  placeholder=" "
                  autoComplete="off"
                  className={`form-control-material ${validationErrors.name ? 'error' : ''}`}
                  required
                  onKeyPress={(event) => {
                      if (!/[a-zA-Z\s]/.test(event.key)) {
                          event.preventDefault();
                      }
                  }}
              />
              <label htmlFor="name">Full Name</label>
              <ValidationError 
                  prefix="Name" 
                  field="name"
                  errors={state.errors}
              />
            </div>

            <div className="form-input-material">
              <input
                  type="tel"
                  name="Phone"
                  id="phone"
                  placeholder=" "
                  autoComplete="off"
                  className="form-control-material"
                  pattern='[0-9]{8}' 
                  required
                  onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                      }
                  }}
              />
              <label htmlFor="phone">Phone Number</label>
              <ValidationError 
                  prefix="Phone" 
                  field="phone"
                  errors={state.errors}
              />
            </div>

            <div className="form-input-material">
              <input
                  type="text"
                  name="Company"
                  id="company"
                  placeholder=" "
                  autoComplete="off"
                  className="form-control-material"
              />
              <label htmlFor="company">Company (Optional)</label>
              <ValidationError 
                  prefix="Company" 
                  field="company"
                  errors={state.errors}
              />
            </div>

            <div className="form-input-material">
              <input
                  type="email"
                  name="Email"
                  id="email"
                  placeholder=" "
                  autoComplete="off"
                  className={`form-control-material ${validationErrors.email ? 'error' : ''}`}
                  required
              />
              <label htmlFor="email">Email</label>
              <ValidationError 
                  prefix="Email" 
                  field="email"
                  errors={state.errors}
              />
            </div>

            <div className="form-input-material">
              <textarea
                  name="Message/Task"
                  id="message"
                  placeholder=" "
                  autoComplete="off"
                  className={`form-control-material ${validationErrors.message ? 'error' : ''}`}
                  required
              />
              <label htmlFor="message">Message</label>
              <ValidationError 
                  prefix="Message" 
                  field="message"
                  errors={state.errors}
              />
            </div>

            <button type="submit" className={`btn btn-primary btn-ghost ${isSuccessMessage ? 'thanks success-text' : ''}`} disabled={state.submitting}>
                {buttonText}
            </button>
        </form>
        </ScrollAnimation>
    </div>
  );
}

export default Contact;
