import React from "react";
import { useState } from "react";
import "../Styles/Projects.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import ScrollAnimation from '../Services/ScrollAnimation';

  
const Projects = () => {
    const [isPopupOpen, setIsPopupOpen] = React.useState(false);
    const [isAnimating, setIsAnimating] = useState(false);

    const togglePopup = () => {
        if (isPopupOpen) {
            setIsAnimating(true);
            setTimeout(() => {
                setIsPopupOpen(false);
                setIsAnimating(false);
            }, 300); 
        } else {
            setIsPopupOpen(true);
            setIsAnimating(true); 
        }
    };

    return (
        <div className="wrapper-projects" id="projectsPage">
            <ScrollAnimation>
            <div className="left-side">
                <h1>Projects</h1>
                <h2>Mobile apps</h2>
                <p>
                    Here you see some of my projects that I have done and I'm proud of.
                </p>
            </div>
            </ScrollAnimation>
            <ScrollAnimation>
            <div className="right-side">
                <div className="box1"></div>
                <div className="box2">
                    <button className="read-more" onClick={togglePopup}>
                        <FontAwesomeIcon icon={faCircleInfo} style={{ fontSize: '35px' }} />              
                    </button>
                </div>
            </div>
            </ScrollAnimation>
            {isPopupOpen && (
                <div className={`popup ${isAnimating ? (isPopupOpen ? "opening" : "closing") : ""}`}>
                    <div className="popup-content">
                        <span className="close" onClick={togglePopup}>&times;</span>
                        <ScrollAnimation>
                        <h2>What is TALKiE</h2>
                        <p>
                        TALKiE is an exam project. The app was created with the intention of helping the students of China studies
                        at Aarhus University to strengthen their Chinese speaking skills. TALKiE uses integrated AI to conduct a
                        conversation with the user within selected everyday scenarios. Since the app focuses on the oral exercise,
                        the conversation proceeds by the user speaking into the mobile's microphone, where the AI-​​bot responds.
                        <br></br><br></br>The app offers conversation topics such as "At a restaurant", "Sports", "Travelling" and many more.
                        </p>
                        </ScrollAnimation>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Projects;

