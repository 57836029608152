import './App.css';
import Navbar from "./Navigation/Navbar";
import Intro from "./Components/Intro";
import About from './Components/About';
import Projects from './Components/Projects';
import Skills from './Components/Skills';
import Contact from './Components/Contact';
import Footer from './Components/Footer';

// For constant width of the container (No horizontal scrolling)
const containerStyle = {
    width: '100%',
    overflowX: 'hidden',
  };

function App() {
  return (
    <div style={containerStyle}>
    <div className="App">
        <div className="Navigation">
            <Navbar />
        </div>
        <div className="Intro">
            <Intro />
        </div>
        <div className="About">
            <About />
        </div>
        <div className="Projects">
            <Projects />
        </div>
        <div className="Skills">
            <Skills />
        </div>
        <div className="Contact">
            <Contact />
        </div>
        <div className="Footer">
            <Footer />
        </div>
    </div>
    </div>
  );
}

export default App;